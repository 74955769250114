@use '../../../styles/@tokens' as tokens;
@use '../../../styles/tools';

.CommentsGroup {
  padding: tokens.$space-600 tokens.$space-600 tokens.$space-700 tokens.$space-400;

  @include tools.card;
}

.CommentsGroup__highlight {
  border-color: tokens.$brand-primary;
}

.CommentsGroup > div {
  padding: 0;
  border: 0;

  &:not(:last-of-type) {
    margin-bottom: tokens.$space-600;
  }
}
