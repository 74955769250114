$transition-hover-duration: 0.15s;
$transition-hover-timing-function: ease-in-out;

@mixin transition-hover {
  transition: color $transition-hover-duration $transition-hover-timing-function,
    background-color $transition-hover-duration $transition-hover-timing-function,
    border-color $transition-hover-duration $transition-hover-timing-function,
    box-shadow $transition-hover-duration $transition-hover-timing-function;
}

@mixin transition-default($properties...) {
  $transition: ();
  @each $property in $properties {
    $transition: append(
      $transition,
      ($property $transition-hover-duration $transition-hover-timing-function),
      $separator: comma
    );
  }

  transition: $transition;
}
