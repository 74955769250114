@use 'sass:map';
@use '../styles/@tokens' as tokens;
@use '../styles/tokens/colors' as colors;
@use "@lmc-eu/spirit-web/scss/tools/breakpoint";
@use "../../../styles/tools" as tools;

.CompanySearch {
  position: relative;
  width: 100%;
  color: colors.$text-secondary-default;

  @include tools.transition-default(max-width);
}

.CompanySearch > svg {
  position: absolute;
  top: 50%;
  left: tokens.$space-400;
  transform: translateY(-50%);
}

.CompanySearch__input {
  display: block;
  width: 100%;
  height: 100%;
  padding: tokens.$space-400 tokens.$space-400 tokens.$space-400 tokens.$space-900;
  font-size: map.get(tokens.$body-medium-text-regular, 'mobile', 'font-size');
  border: 1px solid colors.$border-primary-default;
  border-radius: tokens.$radius-100;

  @include tools.transition-default(border-color);

  @include breakpoint.up(tokens.$breakpoint-tablet) {
    font-size: map.get(tokens.$body-small-text-regular, 'mobile', 'font-size');
  }

  &:active,
  &:focus,
  &:hover {
    border-color: tokens.$action-selected-default;
  }

  &::placeholder {
    opacity: 1;
    color: tokens.$text-secondary-default;
  }
}

.CompanySearch__close {
  position: absolute;
  top: calc(50% + #{tokens.$border-width-200});
  right: 0.5em;
  color: tokens.$focus-default;
  background: transparent;
  border: 0;
  transform: translateY(-50%);

  @include breakpoint.up(tokens.$breakpoint-tablet) {
    display: none;
  }
}
