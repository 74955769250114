@use '../../../styles/@tokens' as tokens;

.List__checkmark {
  padding-left: tokens.$space-700;
  list-style-position: inside;
}

.List__checkmark--tick {
  list-style-image: url('../../../public/icons/tick-brand-primary.svg');
}

.List__checkmark--checkmark-round {
  list-style-image: url('../../../public/icons/checkmark-round.svg');
}

.List__checkmark--cross-round {
  list-style-image: url('../../../public/icons/cross-round.svg');
}
