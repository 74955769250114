@use 'sass:map';
@use '../../../styles/@tokens' as tokens;

.Heading {
  padding-top: tokens.$space-800;
  padding-bottom: tokens.$space-600;
  font-weight: map.get(tokens.$heading-xsmall-text, 'mobile', 'font-weight');
  line-height: map.get(tokens.$heading-xsmall-text, 'mobile', 'line-height');
}

.Heading--withBackground {
  display: flex;
  justify-content: center;
  padding: tokens.$space-500;
  background-color: tokens.$background-cover;
}

.Heading > svg {
  flex-shrink: 0;
  margin-right: tokens.$space-500;
}

.Heading strong {
  font-weight: map.get(tokens.$body-medium-text-bold, 'mobile', 'font-weight');
}
