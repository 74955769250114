@use 'styles/@tokens' as tokens;

.CustomOption {
  /* stylelint-disable declaration-no-important */
  display: flex !important;
  color: tokens.$text-primary-default !important;
  background-color: tokens.$background-basic !important;
  border-radius: tokens.$radius-200;

  &:active,
  &:focus,
  &:hover,
  &:visited {
    color: tokens.$text-primary-default !important;
    background-color: tokens.$background-cover !important;
  }
  /* stylelint-enable declaration-no-important */
}

.CustomOption__text {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}

.CustomOption--isDisabled {
  /* stylelint-disable declaration-no-important */
  color: tokens.$text-primary-disabled !important;

  &:active,
  &:focus,
  &:hover,
  &:visited {
    color: tokens.$text-primary-disabled !important;
    background-color: tokens.$background-basic !important;
  }
  /* stylelint-enable declaration-no-important */
}

.CustomOption--isSelected {
  display: flex;
  margin-left: tokens.$space-400;
  color: tokens.$action-selected-default;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
}