@use "sass:map";
@use '../../styles/@tokens' as tokens;
@use '../../styles/tokens/colors' as colors;
@use "../../node_modules/@lmc-eu/spirit-web/scss/tools/breakpoint";

$navbar-button-size: 2.5rem;

.Navbar {
  font-size: map.get(tokens.$body-small-text-regular, 'mobile', 'font-size');
}

.Navbar__contentWrapper {
  position: relative;
  padding-top: tokens.$space-600;
  padding-bottom: tokens.$space-500;
  text-align: center;
}

.Navbar__contentWrapper--populated {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: tokens.$space-400 tokens.$space-400 tokens.$space-500;

  @include breakpoint.up(tokens.$breakpoint-tablet) {
    padding-inline: tokens.$space-700;
  }

  @include breakpoint.up(tokens.$breakpoint-desktop) {
    padding-right: tokens.$space-900;
    padding-left: tokens.$space-800;
  }
}

.Navbar__content {
  display: flex;
  justify-content: flex-end;

  @include breakpoint.up(tokens.$breakpoint-tablet) {
    flex-grow: 1;
  }
}

.Navbar__searchWrapper {
  display: none;

  @include breakpoint.up(tokens.$breakpoint-tablet) {
    display: flex;
    justify-content: flex-end;
    max-width: 10rem;
    margin-right: tokens.$space-700;
  }
}

.Navbar__searchWrapper--expanded {
  position: absolute;
  top: 1.5 * tokens.$space-300;
  right: 0;
  bottom: 1.5 * tokens.$space-300;
  left: 0;
  display: block;
  z-index: 2;
}

.Navbar__searchWrapper--expanded > div,
.Navbar__searchWrapper--expanded > div > input {
  height: 100%;
}

.Navbar__button {
  width: $navbar-button-size;
  height: $navbar-button-size;
  padding: 0;
}

.Navbar__button--search {
  background: transparent;
  border: 1px solid colors.$border-primary-default;
  border-radius: tokens.$radius-100;

  &:focus,
  &:hover {
    border: 1px solid colors.$border-primary-hover;
  }

  &:active {
    border: 1px solid colors.$border-primary-active;
  }

  &:disabled {
    border: 1px solid colors.$border-primary-disabled;
  }

  @include breakpoint.up(tokens.$breakpoint-tablet) {
    display: none;
  }
}
