@use 'styles/@tokens' as tokens;
@use 'styles/tokens/colors' as colors;
@use '@lmc-eu/spirit-web/scss/tools/breakpoint' as breakpoint;

.Dropdown {
  position: relative;
  width: 100%;
  min-width: 220px;
  padding: tokens.$space-600 tokens.$space-400 tokens.$space-700;
  text-align: left;
  background-color: colors.$background-basic;
  border-radius: tokens.$radius-100;
  box-shadow: tokens.$shadow-300;
  z-index: tokens.$zindex-dropdown;
}

.Dropdown--scrollable {
  overflow-y: auto;
  max-height: 300px;
}

.Dropdown > hr {
  display: block;
  margin-right: -1 * tokens.$space-400;
  margin-left: -1 * tokens.$space-400;
  border: tokens.$border-width-100 tokens.$border-style-100 tokens.$border-primary-default;
}
